<template>
  <div>
    <ul class="photheme-pc-contact-bar">
      <li class="facebook">
        <a
          href="https://www.facebook.com/TienProgrammer?mibextid=LQQJ4d"
          rel="nofollow"
          target="_blank"
        />
      </li>
      <li class="zalo">
        <a href="https://zalo.me/0374276087" rel="nofollow" target="_blank" />
      </li>
      <li class="hotline">
        <a href="tel:0374276087" rel="nofollow" target="_blank" />
      </li>
    </ul>

    <ul class="photheme-mobile-contact-bar">
      <li class="facebook">
        <a
          href="https://www.facebook.com/TienProgrammer?mibextid=LQQJ4d"
          rel="nofollow"
          target="_blank"
        />
      </li>
      <li class="zalo">
        <a href="https://zalo.me/0374276087" rel="nofollow" target="_blank" />
      </li>
      <li class="hotline">
        <a href="tel:0374276087" rel="nofollow" target="_blank" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scope>
/* Hiệu ứng phóng to thu nhỏ */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Phóng to */
  }
  100% {
    transform: scale(1);
  }
}

/* Hiệu ứng nháy */
@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1; /* Giảm độ trong suốt */
  }
  100% {
    opacity: 1;
  }
}

/* Áp dụng hiệu ứng động cho Facebook và Zalo */
.photheme-pc-contact-bar li.facebook,
.photheme-mobile-contact-bar li.facebook {
  animation: pulse 1.5s infinite ease-in-out, blink 2s infinite ease-in-out; /* Kết hợp hiệu ứng */
}

.photheme-pc-contact-bar li.zalo,
.photheme-mobile-contact-bar li.zalo {
  animation: pulse 1.5s infinite ease-in-out, blink 2s infinite ease-in-out; /* Kết hợp hiệu ứng */
}

.photheme-mobile-contact-bar {
  display: none;
}
.photheme-pc-contact-bar {
  left: 1350px;
  bottom: 100px;
  position: fixed;
  z-index: 998;
  margin-bottom: 10;
}
.photheme-pc-contact-bar li {
  width: 44px;
  height: 46px;
  overflow: hidden;
  margin-bottom: 10;
  list-style: none;
}
.photheme-pc-contact-bar li.facebook {
  margin-bottom: 8px;
  background: url(https://1.bp.blogspot.com/-VbN-gi1xiYQ/YBJy3pC7v2I/AAAAAAAAJl0/uBZq95iIwsQpg3Bo_8yb4NMTs6j0GJ12ACLcBGAsYHQ/s44/fb.png);
  background-repeat: no-repeat;
}
.photheme-pc-contact-bar li.zalo {
  background: url(https://1.bp.blogspot.com/-sEGByHlRYxU/YBJy3qQDnjI/AAAAAAAAJl4/ofjocpfb57EGfCEm1-U6_Yp8jhQSP7LcwCLcBGAsYHQ/s0/zl.png);
  background-repeat: no-repeat;
}
.photheme-pc-contact-bar li.hotline {
    background: url(https://1.bp.blogspot.com/-D4cWxxDWjZY/YBJy3qUVuuI/AAAAAAAAJl8/0qw0rdQmDKYz-oPl81-jEHwJgHtlocOEwCLcBGAsYHQ/s250/p2.gif);
    background-repeat: no-repeat;
    background-size: 44px;
  }
.photheme-pc-contact-bar li a {
  display: block;
  width: 44px;
  height: 44px;
}

@media only screen and (max-width: 499px) {
  .photheme-pc-contact-bar {
    display: none;
  }
  .photheme-mobile-contact-bar {
    left: 10px;
    bottom: 10px;
    position: fixed;
    z-index: 998;
    margin-bottom: 0;
    display: block !important;
  }
  .photheme-mobile-contact-bar li {
    display: inline-block;
    float: left;
    margin-right: 7px;
    list-style: none;
  }
  .photheme-mobile-contact-bar li.facebook {
    margin-bottom: 8px;
    background: url(https://1.bp.blogspot.com/-VbN-gi1xiYQ/YBJy3pC7v2I/AAAAAAAAJl0/uBZq95iIwsQpg3Bo_8yb4NMTs6j0GJ12ACLcBGAsYHQ/s44/fb.png);
    background-repeat: no-repeat;
  }
  .photheme-mobile-contact-bar li.zalo {
    background: url(https://1.bp.blogspot.com/-sEGByHlRYxU/YBJy3qQDnjI/AAAAAAAAJl4/ofjocpfb57EGfCEm1-U6_Yp8jhQSP7LcwCLcBGAsYHQ/s0/zl.png);
    background-repeat: no-repeat;
  }
  .photheme-mobile-contact-bar li a {
    display: block;
    width: 44px;
    height: 44px;
  }
  .photheme-mobile-contact-bar li.hotline {
    background: url(https://1.bp.blogspot.com/-D4cWxxDWjZY/YBJy3qUVuuI/AAAAAAAAJl8/0qw0rdQmDKYz-oPl81-jEHwJgHtlocOEwCLcBGAsYHQ/s250/p2.gif);
    background-repeat: no-repeat;
    background-size: 44px;
  }
  
}
</style>
